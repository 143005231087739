<template>
  <div class="login">
    <v-container class="content pb-5">
      <v-form ref="form" lazy-validation>
        <v-row>
          <v-col cols="12" md="5" class="hidden-sm-and-down">
            <v-img
              alt="img"
              class="img"
              src="@/assets/img/auth/img-1.jpg"
              max-width="600"
            />
          </v-col>

          <v-col cols="12" offset-md="1" md="6" align-self="center">
            <v-card color="pa-5 pa-md-8" rounded="xl">
              <h3 class="heading-h3">Sign in</h3>
              <v-row class="mt-2">
                <v-col cols="12" class="d-flex flex-column">
                  <v-text-field
                    v-model="email"
                    :rules="emailRules"
                    label="E-mail"
                    outlined
                    required
                  ></v-text-field>

                  <v-text-field
                    v-model="password"
                    :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                    :rules="passwordRules"
                    :type="showPassword ? 'text' : 'password'"
                    label="Password"
                    counter
                    outlined
                    @click:append="showPassword = !showPassword"
                    required
                  ></v-text-field>

                  <router-link :to="{ name: 'ForgotPassword' }">
                    Forgot your password?
                  </router-link>

                  <v-btn @click="validate()" class="primary mt-4" rounded>
                    Sign In
                  </v-btn>

                  <!-- Don't have an account? Create an account. -->
                  <v-layout class="mt-4">
                    <span>
                      Don't have an account?
                    </span>

                    <router-link :to="{ name: 'Register' }" class="ml-1">
                      Create an account.
                    </router-link>
                  </v-layout>
                </v-col>
              </v-row>
            </v-card>
          </v-col>

          <!-- Overlay -->
          <v-overlay :value="overlay" z-index="10">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
          </v-overlay>
        </v-row>
      </v-form>
    </v-container>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";

// import ability = require('@/plugins/ability');

import { AbilityBuilder, Ability } from "@casl/ability";
// import { ABILITY_TOKEN } from "@casl/vue";

export default {
  name: "Login",

  inject: {
    // $ability: { from: ABILITY_TOKEN },
  },

  data: () => ({
    overlay: false,

    // redirect
    redirect: "/dashboard/inbox",

    showPassword: false,

    // password
    password: "",
    passwordRules: [
      (v) => !!v || "Required",
      (v) => v.length >= 8 || "Can not be less than 8 characters",
      (v) => v.length <= 30 || "Can not be more than 30 characters",
    ],

    // email
    email: "",
    emailRules: [
      (v) => !!v || "Required.",
      (v) => v.length <= 50 || "Max 50 characters",
      (v) => {
        const pattern =
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<script>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return pattern.test(v) || "Invalid e-mail.";
      },
      (v) => v.length <= 100 || "Can not be more than 100 characters",
    ],
  }),

  computed: {
    ...mapState(["auth"]),
  },

  created() {
    let parameters = this.$route.query;

    if ("redirect" in parameters) {
      this.redirect = parameters.redirect;

      if (
        this.redirect == "undefined" ||
        this.redirect == "/login" ||
        this.redirect == "/"
      ) {
        this.redirect = "/dashboard/inbox";
      }
    }
  },

  methods: {
    ...mapMutations({
      setLoggedIn: "auth/setLoggedIn",
      setToken: "auth/setToken",
      setUser: "auth/setUser",
    }),

    validate() {
      this.$refs.form.validate();

      if (this.$refs.form.validate()) {
        this.loginUser();
      }
    },

    setAbilities(permissions) {
      localStorage.setItem("userPermissions", permissions);

      const { can, rules } = new AbilityBuilder(Ability);

      can(permissions);

      this.$ability.update(rules);
    },

    // loginUser
    async loginUser() {
      this.overlay = true;

      // console.log({
      //   email: this.email,
      //   password: this.password,
      // });

      const res = await this.$helpers.createApiData(
        "login",
        {
          email: this.email,
          password: this.password,
        },
        "LoggedIn Successfully!"
      );

      if (res.status == 200) {
        const data = res.data;

        this.setLoggedIn(true);
        this.setToken(data.token);
        this.setUser(data);

        localStorage.setItem("token", data.token);

        // console.log(data);
        // console.log(localStorage.getItem("token"));

        await this.getUserPermissions();
      }

      this.overlay = false;
    },

    // getUserPermissions
    async getUserPermissions() {
      this.overlay = true;

      const res = await this.axios.post("user-permissions");

      if (res.status == 200) {
        const data = res.data;

        // console.log(data);
        this.setAbilities(data);

        this.$router.push(this.redirect);
      }

      this.overlay = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.login {
  // min-height: 100vh;
  .content {
    margin-top: 100px;

    @media only screen and (max-width: 600px) {
      margin-top: 80px;
    }
  }
}
</style>
